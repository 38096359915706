/*************************************
    react-nestable overrides
 */
.nestable-list,
.nestable-item {
    margin-top: 2px !important;
}

/* react-nestable has a bug: if nestable is inside a container that has a scroll, dragged element will have wrong offset */
/* This can be removed if react-nestable fixes the bug */
.nestable-drag-layer > .nestable-list {
    /* Our draggable elements are (usually) 38px. This margin needs to be -38px / 2 */
    /* Not a perfect fix, but good enough */
    margin-top: -19px !important;
}

.fr-box.fr-basic .fr-element.fr-view {
    font-family: Work Sans, sans-serif;
}
